<template>
  <div class="form">
    <ValidationObserver v-slot="{ invalid }" key="sign-up">
      <form class="form" autocomplete="off" @submit.prevent="handleRegister">
        <ValidationProvider v-slot="{ errors }" rules="required|email">
          <SfInput
            v-model="form.email"
            :valid="!errors[0]"
            :error-message="$t(errors[0])"
            name="email"
            :label="$t('Your email')"
            class="form__element"
            @change="(e) => handleEmailInput(e)"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <SfInput
            v-model="form.firstname"
            :valid="!errors[0]"
            :error-message="$t(errors[0])"
            name="first-name"
            :label="$t('First Name')"
            class="form__element"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <SfInput
            v-model="form.lastname"
            v-e2e="'login-modal-lastName'"
            :valid="!errors[0]"
            name="last-name"
            :label="$t('Last Name')"
            class="form__element"
            :error-message="$t(errors[0])"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <SfInput
            v-model="form.phone_number"
            :valid="!errors[0]"
            name="phone"
            label="Telefono"
            class="form__element"
            :error-message="$t(errors[0])"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="`${!form.sdi_code ? 'required|' : ''}email`"
        >
          <SfInput
            v-model="form.certified_email"
            :valid="!errors[0]"
            name="certified_email"
            label="PEC"
            class="form__element"
            :error-message="$t(errors[0])"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="alpha_num|length:16">
          <SfInput
            v-model="form.fiscal_code"
            :valid="!errors[0]"
            name="fiscal_code"
            label="Codice Fiscale"
            class="form__element"
            :error-message="$t(errors[0])"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <SfInput
            v-model="form.company_name"
            :valid="!errors[0]"
            name="company_name"
            label="Nome Azienda"
            class="form__element"
            :error-message="$t(errors[0])"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <SfInput
            v-model="form.tax_code"
            v-e2e="'login-modal-tax_code'"
            :error-message="$t(errors[0]) || $t(passRegex.error)"
            :valid="passRegex.test"
            name="tax_code"
            :label="$t('Partita IVA')"
            class="form__element"
            @input="(e) => validateTaxCode(e, 'input')"
            @blur="(e) => validateTaxCode(e.target._value, 'blur')"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="`${
            !form.certified_email ? 'required|' : ''
          }length:7|alpha_num`"
        >
          <SfInput
            v-model="form.sdi_code"
            :valid="!errors[0]"
            name="sdi_code"
            label="SDI"
            class="form__element"
            :error-message="$t(errors[0])"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <SfSelect
            v-model="selectedCustomerGroup"
            :valid="isValidCustomerGroup"
            :error-message="`${$t('Questo campo è richiesto') || errors[0]}`"
            label="Settore"
            class="form__element"
            @input="handleCustomerGroup"
          >
            <SfSelectOption
              v-for="(group, index) in customerGroupsForSelect"
              :key="`register-form-group-${group.value}-${index}`"
              :value="group.value"
            >
              {{ group.label }}
            </SfSelectOption>
          </SfSelect>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          rules="required|password"
          vid="password"
        >
          <SfInput
            v-model="form.password"
            :valid="!errors[0]"
            :error-message="$t(errors[0])"
            name="password"
            :label="$t('Password')"
            type="password"
            has-show-password
            class="form__element"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          rules="required|confirmed:password"
        >
          <SfInput
            v-model="form.repassword"
            :valid="!errors[0]"
            :error-message="$t(errors[0])"
            name="re-password"
            :label="$t('Re-enter password')"
            type="password"
            has-show-password
            class="form__element repassword"
          />
        </ValidationProvider>
        <div>{{ $t('Having read the information') }} *</div>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <SfCheckbox
            v-model="termsConsent"
            v-e2e="'login-modal-terms'"
            :valid="!errors[0]"
            :error-message="$t(errors[0])"
            name="login-modal-terms"
            :label="
              $t(
                'Yes, I give my consent for my personal data to be used to execute my request'
              )
            "
            class="form__element"
            required
          >
            <template #label>
              <span class="sf-checkbox__label">
                {{
                  $t(
                    'Yes, I give my consent for my personal data to be used to execute my request'
                  )
                }},
                <a :href="localePath('/gdpr')" target="_blank">
                  {{ $t('Click here for full information') }}
                </a>
              </span>
            </template>
          </SfCheckbox>
        </ValidationProvider>
        <div>{{ $t('Consent for marketing purposes') }}</div>
        <SfCheckbox
          v-model="marketingConsent"
          v-e2e="'sign-up-newsletter'"
          :label="
            $t(
              'I consent to my personal data being used for marketing and commercial activities (newsletters, promotions, etc.)'
            )
          "
          name="signupNewsletter"
          class="form__element"
        />
        <div class="error-message" v-if="error.register">
          {{ $t(error.register) }}
        </div>
        <br />
        <SfButton
          v-e2e="'login-modal-submit'"
          type="submit"
          id="register"
          class="sf-button--full-width form__button btn-yellow sf-button"
          :disabled="registering || !termsConsent || invalid || !passRegex.test"
        >
          <SfLoader :class="{ loader: registering }" :loading="registering">
            <div>{{ $t('Create an account') }}</div>
          </SfLoader>
        </SfButton>
      </form>
    </ValidationObserver>
    <div class="action">
      <p class="no-account">{{ $t('or') }}</p>
      <div
        v-e2e="'login-modal-login-to-your-account'"
        class="sf-button-link"
        @click="toggleLoginAndRegisterModal('login')"
      >
        {{ $t('login in to your account') }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  useContext,
  computed,
} from '@nuxtjs/composition-api';
import {
  SfInput,
  SfButton,
  SfCheckbox,
  SfLoader,
  SfSelect,
} from '@storefront-ui/vue';
import { useUser, useUiState, useCart } from '~/composables';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/helpers/customer/regex';
import {
  required,
  email,
  confirmed,
  alpha_num,
  length,
} from 'vee-validate/dist/rules';
import { isEmpty } from 'lodash-es';

extend('email', {
  ...email,
  message: 'Email non valida',
});

extend('required', {
  ...required,
  message: 'Questo campo è richiesto',
});

extend('confirmed', {
  ...confirmed,
  message: 'Le password non coincidono',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value) => customerPasswordRegExp.test(value),
});

extend('alpha_num', {
  ...alpha_num,
  message: 'Il campo deve contenere solo caratteri alfanumerici',
});

extend('length', {
  ...length,
  message: (_, arguments_) =>
    `Questo campo deve contenere ${arguments_.length} caratteri`,
});

export default defineComponent({
  name: 'Register',
  components: {
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    SfSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  setup() {
    const {
      app: {
        $config: { customerGroups },
      },
    } = useContext();
    const { toggleLoginAndRegisterModal } = useUiState();
    const { register, error, registering } = useUser();
    const { checkBulkyProducts } = useCart();
    const passRegex = ref({ test: true, error: '' });
    const form = ref({});
    const termsConsent = ref(false);
    const marketingConsent = ref(false);
    const selectedCustomerGroup = ref();
    const isValidCustomerGroup = ref(false);

    const customerGroupsForSelect = computed(() =>
      customerGroups ? Object.values(customerGroups) : []
    );
    const consents = computed(() => [
      {
        consent_type: 'TERMS_AND_CONDITIONS',
        consent_value: termsConsent.value,
      },
      {
        consent_type: 'PRIVACY',
        consent_value: termsConsent.value,
      },
      { consent_type: 'MARKETING', consent_value: marketingConsent.value },
    ]);

    const validateTaxCode = (e, type) => {
      const taxRegexIT = /^[Ii][Tt]\d{11}$/;
      const taxRegexDigits = /^\d{11}$/;

      passRegex.value = {
        test: e?.toLowerCase()?.includes('it')
          ? taxRegexIT.test(e)
          : taxRegexDigits.test(e),
        error: '',
      };

      if (!passRegex.value.test && type === 'blur') {
        passRegex.value.error = 'Partita IVA non valida';
      }
    };

    const handleRegister = async () => {
      if (!termsConsent.value) return;
      form.value = {
        ...form.value,
        tax_code: form.value?.tax_code?.replace(/\D/g, ''),
      };
      const user = {
        ...form.value,
        consents: consents.value,
        is_subscribed: marketingConsent.value,
        aggregation_id: form.value.tax_code,
      };
      await register(user, selectedCustomerGroup.value);
      await checkBulkyProducts();
    };
    const handleCustomerGroup = () => {
      isValidCustomerGroup.value = !isEmpty(selectedCustomerGroup.value);
    };

    const handleEmailInput = (event) => {
      form.value.email = event?.target?._value?.toLowerCase();
    };

    return {
      form,
      consents,
      handleRegister,
      error,
      customerGroupsForSelect,
      selectedCustomerGroup,
      validateTaxCode,
      passRegex,
      termsConsent,
      marketingConsent,
      toggleLoginAndRegisterModal,
      registering,
      handleEmailInput,
      handleCustomerGroup,
      isValidCustomerGroup,
    };
  },
});
</script>

<style lang="scss" scoped></style>
