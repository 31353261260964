var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    ("custom-input__container custom-input-" + _vm.inputType),
    _vm.disabled && 'custom-input__container__disabled',
    _vm.inputLabel && 'custom-input__container__with-label' ]},[_c('ValidationProvider',{ref:_vm.inputId,staticClass:"custom-input__validator",class:_vm.inputLabel && 'custom-input__validator__with-label',attrs:{"rules":_vm.validateRule,"mode":_vm.interactionMode,"vid":_vm.inputId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [(_vm.inputLabel)?_c('label',{staticClass:"custom-input__label",attrs:{"for":_vm.inputId}},[_vm._v("\n      "+_vm._s(_vm.$gt(_vm.inputLabel)))]):_vm._e(),_vm._v(" "),((_vm.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"custom-input",class:[
        errors[0] && 'custom-input__has-errors',
        _vm.inputLabel && 'custom-input__with-label',
        _vm.disabled && 'custom-input__disabled' ],style:(_vm.overrideStyle || {}),attrs:{"id":_vm.inputId,"name":_vm.inputName,"placeholder":_vm.inputPlaceholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,null)>-1:(_vm.value)},on:{"input":function($event){return _vm.handleChange()},"blur":function($event){return _vm.handleBlur()},"focus":function($event){return _vm.handleFocus()},"change":function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}}}}):((_vm.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"custom-input",class:[
        errors[0] && 'custom-input__has-errors',
        _vm.inputLabel && 'custom-input__with-label',
        _vm.disabled && 'custom-input__disabled' ],style:(_vm.overrideStyle || {}),attrs:{"id":_vm.inputId,"name":_vm.inputName,"placeholder":_vm.inputPlaceholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.value,null)},on:{"input":function($event){return _vm.handleChange()},"blur":function($event){return _vm.handleBlur()},"focus":function($event){return _vm.handleFocus()},"change":function($event){_vm.value=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"custom-input",class:[
        errors[0] && 'custom-input__has-errors',
        _vm.inputLabel && 'custom-input__with-label',
        _vm.disabled && 'custom-input__disabled' ],style:(_vm.overrideStyle || {}),attrs:{"id":_vm.inputId,"name":_vm.inputName,"placeholder":_vm.inputPlaceholder,"type":_vm.inputType},domProps:{"value":(_vm.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.value=$event.target.value},function($event){return _vm.handleChange()}],"blur":function($event){return _vm.handleBlur()},"focus":function($event){return _vm.handleFocus()}}}),_vm._v(" "),(errors[0])?_c('div',{staticClass:"custom-input__errors"},[_vm._v("\n      "+_vm._s(_vm.$t(errors[0]))+"\n    ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }