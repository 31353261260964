<template>
  <ol>
    <div v-if="!product && cartItems.length === 0">
      AGGIUNGI DEI PRODOTTI AL CARRELLO PER POTER RICHIEDERE UN PREVENTIVO
    </div>
    <ol
      class="quote-modal__products__list"
      v-if="!product && cartItems.length > 0"
    >
      <div class="quote-modal__products__title">ARTICOLI</div>
      <li
        v-for="(item, index) in cartItems"
        :key="`request-quote-recap-${index}`"
      >
        {{
          `${item.product.name} (SKU ${item.product.merchant_sku}) - QTA: ${item.quantity}`
        }}
      </li>
    </ol>
    <div v-else-if="product">
      <div class="quote-modal__products__title">ARTICOLI</div>
      {{ `${product.name} (SKU ${product.merchant_sku}) - QTA: ${quantity}` }}
    </div>
  </ol>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
// import {} from '@storefront-ui/vue';

export default defineComponent({
  name: 'QuoteModalRecap',
  props: {
    product: {
      type: Object,
      default: null,
    },
    cartItems: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const quantity = computed(
      () =>
        props?.product?.configurable_product_options_selection?.variant
          ?.venditamultiplidiarticoliweb ||
        props?.product?.venditamultiplidiarticoliweb ||
        1
    );
    return {
      quantity,
    };
  },
});
</script>

<style lang="scss" scoped>
.quote-modal__products {
  &__title {
    @include heading-h4;
    margin-bottom: 1rem;
  }
  &__list {
    list-style: decimal inside;
  }
}
</style>
