<template>
  <nuxt-link :to="localePath('/')" class="sf-header__logo">
    <picture v-if="headerLogo && headerLogo.image">
      <source
        media="(min-width: 767px)"
        :srcset="
          headerLogo.image.desktop
            ? headerLogo.image.desktop
            : '/icons/placeholder.svg'
        "
      />
      <img
        :src="
          headerLogo.image.mobile
            ? headerLogo.image.mobile
            : '/icons/placeholder.svg'
        "
        :alt="headerLogo.image.alt"
        @error="handleImageError"
      />
    </picture>
  </nuxt-link>
</template>

<script>
import {
  defineComponent,
  ref,
  useFetch,
  shallowRef,
} from '@nuxtjs/composition-api';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import { useI18n } from '~/helpers/hooks/usei18n';

export default defineComponent({
  name: 'HeaderLogo',
  setup() {
    const { locale } = useI18n();
    // Fetching the dynamic data for homepage hero image
    const { blocks, loading, loadBlocks } = useContent(
      `header-logos-${locale.includes('it') ? 'it' : 'en'}`
    );
    const headerLogoSelector = shallowRef(
      `header-logos-${locale.includes('it') ? 'it' : 'en'}`
    );
    const headerLogo = ref();

    useFetch(async () => {
      await loadBlocks({ identifiers: [headerLogoSelector.value] });

      try {
        const blockData = contentGetters.getCmsBlockContent(blocks.value);
        headerLogo.value =
          blockData[headerLogoSelector.value].data['header-logo'];
      } catch {
        console.warn('Unable to fetch block content.');
      }
    });
    return {
      headerLogo,
      loading,
    };
  },
  methods: {
    handleImageError(event) {
      event.target.src = '/icons/logo.svg';
    },
  },
});
</script>

<style lang="scss" scoped>
.sf-header__logo {
  @include for-desktop {
    align-items: center;
    display: inline-flex;
  }
  picture {
    min-height: 6.563rem;
    display: var(--flex);
    align-items: center;
    justify-content: center;
    @include to-tablet-1200 {
      min-height: 4.438rem;
      @include to-mobile-max {
        min-height: 3rem;
      }
    }
  }
}
</style>
