<template>
  <div
    v-if="type === 'label' || type === 'icon'"
    class="custom-label"
    :class="[
      { 'custom-label__disabled': disabled || showLoader },
      { 'custom-label__full-width': isFullWidth },
    ]"
    :style="specificWidth ? { width: specificWidth } : {}"
    @click="handleClick"
  >
    <SfLink
      v-if="link"
      :link="link"
      :class="`custom-label__label custom-label__label__${theme}`"
      :target="target"
    >
      <LoadingDots v-if="showLoader" />
      <slot v-else />
      <component
        v-if="hasIcon && type !== 'icon'"
        :is="theme === 'yellow' ? 'IconArrowBlack' : 'IconArrowWhite'"
        class="custom-label__icon"
      />
    </SfLink>
    <span v-else :class="`custom-label__label custom-label__label__${theme}`">
      <LoadingDots v-if="showLoader" />
      <slot v-else />
      <component
        v-if="hasIcon && type !== 'icon'"
        :is="theme === 'yellow' ? 'IconArrowBlack' : 'IconArrowWhite'"
        class="custom-label__icon"
      />
    </span>
  </div>
  <SfButton
    v-else
    :disabled="disabled || showLoader"
    :type="type"
    :link="link"
    :aria-label="ariaLabel"
    :class="[
      `custom-button custom-button__${theme}`,
      { 'custom-button__transparent': isTransparent },
      { 'custom-button__transparent-on-hover': isTransparentOnHover },
      { 'custom-button__disabled': disabled || showLoader },
      { 'custom-button__full-width': isFullWidth },
    ]"
    @click="handleClick"
    :style="specificWidth ? { width: specificWidth } : {}"
    :target="target"
  >
    <LoadingDots v-if="showLoader" />
    <slot v-else />
    <component
      v-if="hasIcon"
      :is="
        theme === 'yellow' || theme === 'white'
          ? 'IconArrowBlack'
          : 'IconArrowWhite'
      "
      class="custom-button__icon"
    />
  </SfButton>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
// import { IconArrowBlack, IconArrowWhite } from '~/components/General/Icons';
import { SfButton, SfLink } from '@storefront-ui/vue';
import LoadingDots from '~/components/General/LoadingDots.vue';

export default defineComponent({
  name: 'CustomButton',
  components: {
    SfLink,
    SfButton,
    LoadingDots,
  },
  props: {
    theme: {
      type: String,
      default: 'yellow',
      validator(value) {
        return ['yellow', 'black', 'white'].includes(value);
      },
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    isTransparentOnHover: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) =>
        ['button', 'submit', 'reset', 'label', 'icon'].includes(value),
    },
    link: {
      type: String,
      default: '',
    },
    ariaLabel: {
      type: String,
      default: 'button',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    specificWidth: {
      type: [String, undefined],
      default: undefined,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  setup(props, { emit }) {
    const handleClick = () => {
      if (!props.disabled && props.link === '') emit('click');
    };
    return { handleClick };
  },
});
</script>

<style lang="scss" scoped>
.custom-label {
  @include default-outline;
  @include cta-text;
  @include form-element-default-mobile-size;
  display: flex;
  align-items: center;
  border: none;
  &__label {
    display: contents;
    cursor: pointer;
    &__yellow,
    &__white {
      color: var(--c-black);
    }
    &__black {
      color: var(--c-white);
    }
  }
  &__disabled {
    cursor: auto;
    pointer-events: none;
    .custom-label__label {
      color: var(--c-black-gray);
    }
    .custom-label__icon path {
      stroke: var(--c-black-gray);
    }
  }
  &__full-width {
    width: 100%;
    min-width: 7.5rem;
  }
  &__icon {
    width: 1.375rem;
    height: 1.375rem;
  }
}
.custom-button {
  @include form-element-default-mobile-size;
  @include default-outline;
  @include cta-text;

  &__yellow {
    background-color: var(--c-primary);
    &:hover,
    &:active {
      @include from-desktop-min {
        color: var(--c-black);
        background: var(--c-white);
        outline: 1px solid var(--c-black);
        svg path {
          stroke: var(--c-white);
        }
      }
      @include to-tablet-max {
        background: var(--c-white);
      }
    }
  }
  &__black {
    color: var(--c-white);
    background: var(--c-black);
    &:hover,
    &:active {
      color: var(--c-black);
      background: var(--c-white);
      outline: 1px solid var(--c-black);
      svg path {
        stroke: var(--c-black);
      }
    }
  }
  &__white {
    color: var(--c-black);
    background: var(--c-white);
    @include default-outline-black;
    &:hover,
    &:active {
      @include from-desktop-min {
        color: var(--c-white);
        background: var(--c-black);
        outline: 1px solid var(--c-white);
        svg path {
          stroke: var(--c-white);
        }
      }
      @include to-tablet-max {
        background: var(--c-white);
      }
    }
  }
  &__transparent:not(:hover):not(:active) {
    background: transparent;
  }
  &__transparent-on-hover:hover,
  &__transparent-on-hover:active {
    background: transparent;
  }
  &__disabled {
    pointer-events: none;
    opacity: 0.3;
    background-color: var(--c-grey);
    color: var(--c-black);
    svg path {
      stroke: var(--c-black-gray);
    }
    @include from-desktop-min {
      &:hover {
        color: var(--c-black);
        background: var(--c-black-gray);
        svg path {
          stroke: var(--c-black);
        }
      }
    }
  }
  &:before {
    content: none;
  }
  &:active {
    text-decoration: none;
  }
  &__icon {
    overflow: visible;
  }
  &__full-width {
    width: 100%;
    max-width: 100%;
  }
}

@include from-desktop-min {
  .custom-button,
  .custom-label {
    @include form-element-default-desktop-size;
  }
}
</style>
