var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type === 'label' || _vm.type === 'icon')?_c('div',{staticClass:"custom-label",class:[
    { 'custom-label__disabled': _vm.disabled || _vm.showLoader },
    { 'custom-label__full-width': _vm.isFullWidth } ],style:(_vm.specificWidth ? { width: _vm.specificWidth } : {}),on:{"click":_vm.handleClick}},[(_vm.link)?_c('SfLink',{class:("custom-label__label custom-label__label__" + _vm.theme),attrs:{"link":_vm.link,"target":_vm.target}},[(_vm.showLoader)?_c('LoadingDots'):_vm._t("default"),_vm._v(" "),(_vm.hasIcon && _vm.type !== 'icon')?_c(_vm.theme === 'yellow' ? 'IconArrowBlack' : 'IconArrowWhite',{tag:"component",staticClass:"custom-label__icon"}):_vm._e()],2):_c('span',{class:("custom-label__label custom-label__label__" + _vm.theme)},[(_vm.showLoader)?_c('LoadingDots'):_vm._t("default"),_vm._v(" "),(_vm.hasIcon && _vm.type !== 'icon')?_c(_vm.theme === 'yellow' ? 'IconArrowBlack' : 'IconArrowWhite',{tag:"component",staticClass:"custom-label__icon"}):_vm._e()],2)],1):_c('SfButton',{class:[
    ("custom-button custom-button__" + _vm.theme),
    { 'custom-button__transparent': _vm.isTransparent },
    { 'custom-button__transparent-on-hover': _vm.isTransparentOnHover },
    { 'custom-button__disabled': _vm.disabled || _vm.showLoader },
    { 'custom-button__full-width': _vm.isFullWidth } ],style:(_vm.specificWidth ? { width: _vm.specificWidth } : {}),attrs:{"disabled":_vm.disabled || _vm.showLoader,"type":_vm.type,"link":_vm.link,"aria-label":_vm.ariaLabel,"target":_vm.target},on:{"click":_vm.handleClick}},[(_vm.showLoader)?_c('LoadingDots'):_vm._t("default"),_vm._v(" "),(_vm.hasIcon)?_c(_vm.theme === 'yellow' || _vm.theme === 'white'
        ? 'IconArrowBlack'
        : 'IconArrowWhite',{tag:"component",staticClass:"custom-button__icon"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }