<template>
  <SfRadio
    :label="label"
    :value="value"
    :selected="selected"
    :name="name"
    class="custom-radio"
    :id="radioId"
    :disabled="disabled"
    @change="handleChange"
  >
    <template #label>
      <span v-if="label" class="custom-radio__label">
        {{ label }}
      </span>
    </template>
    <template #checkmark>
      <CustomCheckmark
        :selected="isSelected"
        :disabled="disabled"
        :circle-radius="true"
      />
    </template>
  </SfRadio>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { SfRadio } from '@storefront-ui/vue';
import CustomCheckmark from './CustomCheckmark.vue';

export default defineComponent({
  name: 'CustomRadio',
  components: {
    SfRadio,
    CustomCheckmark,
  },
  model: {
    prop: 'selected',
  },
  props: {
    label: {
      type: String,
      default: 'Test Label',
    },
    details: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'test-name',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: String || null,
      default: null,
    },
    radioId: {
      type: String,
      default: 'test-id',
    },
  },
  setup(props, { emit }) {
    const isSelected = computed(() => props.selected === props.value);

    const handleChange = (value) => {
      emit('change', value);
      emit('input', value);
    };

    return {
      handleChange,
      isSelected,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-radio {
  &__label {
    @include paragraph-regular;
    display: flex;
    justify-content: var(--radio-custom-label-flex-justify);
  }
}
</style>

<style lang="scss">
.custom-radio.sf-radio {
  margin-bottom: 2rem;
  .sf-radio {
    &__container {
      padding: 0;
      flex-direction: var(--radio-label-flex-direction);
      gap: 1.25rem;
      align-items: center;
      .sf-radio__content {
        margin: var(--radio-content-right-margin);
      }
    }
  }
  &.is-active {
    background-color: transparent;
  }
}
</style>
