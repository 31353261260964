import { render, staticRenderFns } from "./CustomRadio.vue?vue&type=template&id=fad60114&scoped=true&"
import script from "./CustomRadio.vue?vue&type=script&lang=js&"
export * from "./CustomRadio.vue?vue&type=script&lang=js&"
import style0 from "./CustomRadio.vue?vue&type=style&index=0&id=fad60114&prod&lang=scss&scoped=true&"
import style1 from "./CustomRadio.vue?vue&type=style&index=1&id=fad60114&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fad60114",
  null
  
)

export default component.exports