<template>
  <div class="quote-modal">
    <SfModal
      :visible="isQuoteModalOpen"
      class="modal"
      @close="toggleQuoteModal"
    >
      <template #modal-bar>
        <SfBar
          class="quote-modal__bar"
          :close="true"
          title="Preventivo o informazioni"
          @click:close="toggleQuoteModal"
        />
      </template>
      <QuoteModalRecap
        v-if="!loadingQuoteModal && canSendQuoteRequest"
        :product="product"
        :cart-items="cartItems"
      />
      <LoadingDots v-if="loadingQuoteModal" />
      <ValidationObserver
        v-else-if="!loadingQuoteModal && canSendQuoteRequest"
        class="quote-modal__form"
        v-slot="{ invalid }"
      >
        <CustomInput
          v-model="customerEmail"
          :input-value="customerEmail"
          input-name="quote-modal-email"
          input-id="quote-modal-email"
          input-label="Email"
          validate-rule="required|email"
          :override-style="{ width: '100%' }"
        />
        <CustomInput
          v-model="businessName"
          :input-value="businessName"
          input-name="quote-modal-business-name"
          input-id="quote-modal-business-name"
          input-label="Ragione sociale"
          validate-rule="required"
          :override-style="{ width: '100%' }"
        />
        <CustomInput
          v-model="vat"
          :input-value="vat"
          validate-rule="required|length:11|alpha_num"
          input-name="quote-modal-vat"
          input-id="quote-modal-vat"
          input-label="P.IVA"
          :override-style="{ width: '100%' }"
        />
        <CustomInput
          v-model="province"
          :input-value="province"
          input-name="quote-modal-province"
          input-id="quote-modal-province"
          input-label="Provincia"
          validate-rule="required"
          :override-style="{ width: '100%' }"
        />
        <CustomTextarea
          v-model="notes"
          label="NOTE"
          name="quote-modal-notes"
          validate-rule="max:1000"
          textarea-id="quote-modal-notes"
        />
        <CustomButton
          class="quote-modal__cta"
          @click="sendQuoteRequest"
          :disabled="invalid"
        >
          INVIA LA RICHIESTA
        </CustomButton>
      </ValidationObserver>
    </SfModal>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from '@nuxtjs/composition-api';
import { SfModal, SfBar } from '@storefront-ui/vue';
import { ValidationObserver } from 'vee-validate';
import {
  useUiState,
  useGeminiApi,
  useCart,
  useUiNotification,
  useUser,
} from '~/composables';
import formSpree from '~/helpers/formSpree/formSpree';
import {
  CustomInput,
  CustomTextarea,
  LoadingDots,
  CustomButton,
} from '~/components/General/FormElements';
import QuoteModalRecap from './Internal/QuoteModalRecap.vue';

export default defineComponent({
  name: 'QuoteModal',
  components: {
    SfModal,
    ValidationObserver,
    SfBar,
    CustomInput,
    CustomTextarea,
    LoadingDots,
    CustomButton,
    QuoteModalRecap,
  },
  setup() {
    const { send: sendNotification } = useUiNotification();
    const { toggleQuoteModal, isQuoteModalOpen, quoteAdditionalData } =
      useUiState();
    const { sendForm } = formSpree();
    const { getCustomerPermissions, geminiData: customerPermissions } =
      useGeminiApi();
    const loadingQuoteModal = ref(true);
    const { user, isAuthenticated, load: loadUser } = useUser();
    const customerEmail = ref();
    const vat = ref();
    const businessName = ref();
    const province = ref();
    const notes = ref();
    const cannotBuy = computed(
      () =>
        customerPermissions.value?.canLogin &&
        !customerPermissions.value?.canBuy
    );
    const { cart, cartItems } = useCart();
    const canSendQuoteRequest = computed(() => {
      if (!quoteAdditionalData.value) {
        return (
          ((isAuthenticated.value && cannotBuy.value) ||
            !isAuthenticated.value) &&
          cartItems.value.length > 0
        );
      }
      return isAuthenticated.value ? !customerPermissions.value?.canBuy : true;
    });

    onMounted(async () => {
      if (!quoteAdditionalData.value) {
        if (isAuthenticated.value) {
          await getCustomerPermissions(user.value);
          customerEmail.value = user.value.email;
        }
      } else {
        if (!isAuthenticated.value) {
          await loadUser();
        }
        if (isAuthenticated.value) {
          customerEmail.value = user.value.email;
        }
      }
      loadingQuoteModal.value = false;
    });

    const sendQuoteRequest = async () => {
      const data = {
        email: customerEmail.value,
        notes: notes.value,
        p_iva: vat.value,
        ragione_sociale: businessName.value,
        province: province.value,
      };
      if (quoteAdditionalData.value) {
        // if configurable_product_options_selection.variant then use its merchant_sku else use the merchant_sku
        const sku =
          quoteAdditionalData.value?.configurable_product_options_selection
            ?.variant?.merchant_sku || quoteAdditionalData.value.merchant_sku;
        data.PRODOTTO = `${
          quoteAdditionalData.value.name
        } (SKU ${sku}) - QTA: ${
          quoteAdditionalData.value?.configurable_product_options_selection
            ?.variant?.venditamultiplidiarticoliweb ||
          quoteAdditionalData.value?.venditamultiplidiarticoliweb ||
          1
        }`;
      } else {
        cart.value.items.forEach((item, index) => {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          data[`PRODOTTO ${index + 1}`] = `${index + 1}: ${
            item.product.name
          } (SKU ${item.product.merchant_sku}) - QTA: ${item.quantity}`;
        });
      }
      const formSpreeResponse = await sendForm('mjvqzabd', data);
      if (formSpreeResponse) {
        toggleQuoteModal();
        sendNotification({
          id: Symbol('quote_request_sent'),
          message: `La tua richiesta è stata inviata con successo.`,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Richiesta inviata',
        });
        return;
      }
      sendNotification({
        id: Symbol('quote_request_error'),
        message: `Non è stato possibile inviare la tua richiesta.`,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Errore',
      });
    };

    return {
      toggleQuoteModal,
      isQuoteModalOpen,
      customerEmail,
      vat,
      businessName,
      province,
      user,
      loadingQuoteModal,
      notes,
      cartItems,
      sendQuoteRequest,
      cannotBuy,
      canSendQuoteRequest,
      product: quoteAdditionalData,
    };
  },
});
</script>

<style lang="scss">
.quote-modal {
  .sf-modal {
    &__container {
      z-index: 4;
    }
    &__close {
      display: none;
    }
  }
  &__form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 2rem;
  }
  &__cta {
    margin: 0 auto;
  }
}
</style>
